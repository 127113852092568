<template>
  <div class="contain">
    <navbar title="发票抬头管理"></navbar>
    <div class="list">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div v-for="(item, index) in list" :key="index" class="item">
          <van-swipe-cell>
            <div class="item-con"  @click="setChoose(item)">
              <div class="item-con-cell-f">
                <div>
                  <span class="type">企业</span>
                  <span class="default-tips" v-if="item.is_default">默认</span>
                </div>
                <div @click.stop="add(item.id)">
                  <img class="edit-pic" :src="editPic">
                </div>
              </div>
              <div class="item-con-cell">
                <span>企业名 </span>
                <span class="cell-c" :style="'width:' + $px2vw(520)">{{item.company_name}}</span>
              </div>
              <div class="item-con-cell">
                <span>纳税人识别码 </span>
                <span class="cell-c" :style="'width:' + $px2vw(440)">{{item.taxpayer_code}}</span>
              </div>
            </div>
            <template #right>
              <div class="del-item" @click="del(item.id, index)">删除</div>
            </template>
          </van-swipe-cell>
        </div>
      </van-list>
    </div>
    <div class="footer">
      <div class="add-btn" @click="add()">
        <van-icon name="add-o" color="#fff" :size="$px2vw(36)"/>
        <div class="add">添加企业发票抬头</div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/navbar.vue";
import EditPic from '@/assets/images/icon/edit.png'
import {billList, delBill} from '@/api/invoice.js'

export default {
  name: 'invoice',
  components: {
    Navbar
  },
  data() {
    return {
      editPic: EditPic,
      loading: false,
      finished: false,
      list: []
    }
  },
  mounted(){
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener('popstate', this.listernerBack, false);
    }
  },
  methods: {
    onLoad() {
      billList()
        .then(res => {
          if (res.code == 0) {
            this.list = res.data
            this.finished = true
          } else {
            this.$toast(res.msg)
          }
          this.loading = false
        })
    },
    del(id, index) {
      delBill({id: id})
        .then(res => {
          if (res.code ==0) {
            this.list.splice(index, 1)
          } else {
            this.$toast(res.msg)
          }
        }).catch(err => {
          console.log(err)
          this.$toast('操作失败！')
        })
    },
    add(id = 0) {
      var name = 'invoiceDetail'
      if (this.$route.name == 'orderInvoice') name = 'orderInvoiceDetail'
      if (id) {
      this.$router.push({name: name, query: {id: id}})
      } else {
        this.$router.push({name: name})
      }
    },
    setChoose(item) {
      if (this.$route.name == 'orderInvoice') {
        this.$emit('choose', {val: item, type: 'invoice'})
      }
    },
    listernerBack() {
      this.setChoose(null)
      if (this.$route.name != 'orderInvoice') {
        this.$router.go(-1)
      }
    }
  },
  destroyed() {
    window.removeEventListener('popstate', this.listernerBack, false);
  }
}
</script>

<style lang="scss" scoped>
.contain{
  :deep(.van-list__finished-text){
    margin: 0 20px;
  }
}
.list{
  @include safe-area-inset-bottom(160px)
}
.item{
  margin: 20px 20px 0;
  background: #fff;
  border-radius: 10px;

  .item-con{
    padding: 30px 40px 25px 30px;
    box-sizing: border-box;

    .item-con-cell{
      width: 100%;
      display: flex;
      color: #999999;
      font-size: 28px;

      .cell-c{
        margin-left: 20px;
        color: #4F4F4F;
        @include not-wrap;
      }
    }
    .item-con-cell:not(:first-child) {
      margin-top: 10px;
    }
    .item-con-cell:first-child {
      margin-top: 14px;
    }
    .item-con-cell-f{
      display: flex;
      justify-content: space-between;
      align-items: center;
      .type{
        font-size: 30px;
        font-weight: bold;
        color: #4F4F4F;
      }
      .default-tips{
        margin-left: 20px;
        padding: 0 18px;
        font-size: 22px;
        color: #FFFFFF;
        background: #1AA3FF;
        border-radius: 4px;
      }
      .edit-pic{
        display: block;
        width: 32px;
        height: 32px;
      }
    }
  }
  .del-item{
    width: 120px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FF6E6E;
    color: #fff;
    font-size: 30px;
    border-radius: 0px 10px 10px 0px;
  }

  :deep(.van-swipe-cell__right){
    right: -1px;
  }
}
.footer{
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 10px 29px 30px;
  box-sizing: border-box;
  background: #F6F6F6;
  @include safe-area-inset-bottom('40px');
  .add-btn{
    height: 88px;
    background: #1AA3FF;
    color: #fff;
    border-radius: 41px; 
    font-size: 36px;
    line-height: 88px;
    text-align: center;
    .add{
      display: inline-block;
      margin-left: 24px;
    }
  }
}
</style>